import PropTypes from "prop-types"
import React from "react"
import { Grid, Row, Col } from 'react-styled-flexboxgrid'
import { AnchorLink } from "gatsby-plugin-anchor-links"

const Nav = () => {

  return (
    <nav className="site-nav">
      <Grid fluid>
        <Row between="xs">
          <Col sm xs={12}>
            <div className="site-nav__nav">
              <AnchorLink to="#manifesto" className="site-nav__link">
                Manifesto
              </AnchorLink>
              <AnchorLink to="#download" className="site-nav__link">
                Descarga
              </AnchorLink>
            </div>
          </Col>
        </Row>
      </Grid>
    </nav>
  )
}

Nav.propTypes = {
  siteTitle: PropTypes.string,
}

Nav.defaultProps = {
  siteTitle: ``,
}

export default Nav
