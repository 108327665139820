import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Grid, Col, Row } from "react-styled-flexboxgrid"
import Img from "gatsby-image"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

const Folios = () => {
  const data = useStaticQuery(graphql`
    query {
      allFile(
        filter: { relativeDirectory: { eq: "folios" } }
        sort: { fields: name, order: ASC }
      ) {
        edges {
          node {
            childImageSharp {
              fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid
              }
            }
            relativePath
          }
        }
      }
    }
  `)

  return (
    <div className="folios">
      <Grid fluid>
        <Row center="xs">
          <Col xs={12}>
            <h1 className="folios__title">Sobre os folios</h1>
          </Col>
        </Row>
        <Row>
          <Col sm={6} xs={12}>
            <div className="folios__image">
              <Img fluid={data.allFile.edges[0].node.childImageSharp.fluid} />
            </div>
            <div className="folios__description">
              <strong>Lista: </strong>
              corresponde coa actividade do barco, a sétima (7ª) é de recreo.
              Outras listas poden ser mercantes (2ª), pesca (3ª), auxiliares de
              cultivo (4ª), etc.
            </div>
          </Col>
          <Col sm={6} xs={12}>
            <div className="folios__image">
              <Img fluid={data.allFile.edges[1].node.childImageSharp.fluid} />
            </div>
            <div className="folios__description">
              <strong>Provincia marítima: </strong>
              neste caso de Vilagarcia (VILL). As outras provincias son Burela
              (LU), Ferrol (FE), Coruña (CO) e Vigo (VI).
            </div>
          </Col>
          <Col sm={6} xs={12}>
            <div className="folios__image">
              <Img fluid={data.allFile.edges[2].node.childImageSharp.fluid} />
            </div>
            <div className="folios__description">
              <strong>Distrito marítimo: </strong>
              indica onde foi inscrito o barco, neste caso VILL 4 é O Grove.
              Outros exemplos poden ser VILL 1 para Ribeira ou VI 1 para
              Portonovo.
            </div>
          </Col>
          <Col sm={6} xs={12}>
            <div className="folios__image">
              <Img fluid={data.allFile.edges[3].node.childImageSharp.fluid} />
            </div>
            <div className="folios__description">
              <strong>Número de asento e ano: </strong>
              neste caso indica que este foi o trixésimo quinto (35) barco inscrito
              (n'O Grove) no ano 1998.
            </div>
          </Col>
        </Row>
      </Grid>
    </div>
  )
}

export default Folios
