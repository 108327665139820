import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Grid, Col, Row } from 'react-styled-flexboxgrid'
import Img from "gatsby-image"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

const FoliosUm = () => {
  const data = useStaticQuery(graphql`
    query {
      allFile(
        filter: {relativeDirectory: {eq: "foliosUm"}}
        sort: { fields: name, order: ASC }
        ) {
        edges {
          node {
            childImageSharp {
              fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid
              }
            }
            relativePath
          }
        }
      }
    }
  `)

  return (
    <div className="section">
      <Grid fluid>
        <Row>
          <Col sm={3} xs={6}>
            <div className="section__image">
              <Img fluid={data.allFile.edges[0].node.childImageSharp.fluid} />
            </div>
          </Col>
          <Col sm={3} xs={6}>
            <div className="section__image">
              <Img fluid={data.allFile.edges[1].node.childImageSharp.fluid} />
            </div>
          </Col>
          <Col sm={3} xs={6}>
            <div className="section__image">
              <Img fluid={data.allFile.edges[2].node.childImageSharp.fluid} />
            </div>
          </Col>
          <Col sm={3} xs={6}>
            <div className="section__image">
              <Img fluid={data.allFile.edges[3].node.childImageSharp.fluid} />
            </div>
          </Col>
        </Row>
      </Grid>
    </div>
  )
}

export default FoliosUm
